import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  panoramas: [],
  isLoading: false,
  errorMessage: '',
};

const panoramaSlice = createSlice({
  name: 'panorama',
  initialState,
  reducers: {
    fetchPanoramaStart(state) {
      state.isLoading = true;
    },
    fetchPanoramaSuccess(state, action) {
      state.isLoading = false;
      state.panoramas = action.payload;
    },
    fetchPanoramaFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { fetchPanoramaStart, fetchPanoramaSuccess, fetchPanoramaFailure } = panoramaSlice.actions;
export default panoramaSlice.reducer;
