// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchArchivedPrograms } from '../redux/actions/archiveActions';
// import { fetchMoviesList, toggleLike } from '../redux/actions/moviesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import magazineCover from '../assets/cove_page.png';
// import magazinePdf from '../assets/surabhi_spoorthi.pdf';
// import "../styles/global.css";
// import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
// import StreamingBar from '../components/StreamingBar';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { setArchiveUrl, setProgram } from '../redux/reducers/streamingBarSlice';

// const SVerse = () => {
//   const dispatch = useDispatch();
//   const archivedPrograms = useSelector((state) => state.archive.archivedPrograms);
//   const isLoadingArchived = useSelector((state) => state.archive.loading);
//   const archivedError = useSelector((state) => state.archive.error);

//   const movies = useSelector((state) => state.movies.movies);
//   const isLoadingMovies = useSelector((state) => state.movies.loading);
//   const moviesError = useSelector((state) => state.movies.error);

//   const ads = useSelector((state) => state.ads.ads);
//   const isLoadingAds = useSelector((state) => state.ads.loading);
//   const adsError = useSelector((state) => state.ads.error);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [selectedDate, setSelectedDate] = useState(new Date());
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);

//   useEffect(() => {
//     dispatch(fetchArchivedPrograms(selectedDate));
//     dispatch(fetchMoviesList());
//     dispatch(fetchAds(true));
//   }, [dispatch, selectedDate]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBannerAdIndex(prevIndex => prevIndex + 1);
//     }, 300000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleToggleLike = (movieId) => {
//     dispatch(toggleLike(movieId));
//   };

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleArchivedProgramClick = (program) => {
//     dispatch(setArchiveUrl(program.playLink));
//     dispatch(setProgram({
//       showPic: program.image,
//       title: program.title,
//       rjName: program.rjName || ''
//     }));
//   };

//   return (
//     <div className="relative font-sans min-h-screen pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-20' : 'ml-64'}`}>
//         {/* Archived Programs Section */}
//         <div className="p-8 pt-10 bg-gray-50 dark:bg-gray-900">
//           <div className="flex justify-between items-center mb-4">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-[.10em]">
//               Archived <span className="text-blue-600">Programs</span>
//             </h2>
//             <div className="flex items-center space-x-2 pr-36">
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 maxDate={new Date()}
//                 minDate={new Date().setDate(new Date().getDate() - 25)}
//                 customInput={<CustomDateInput />}
//               />
//             </div>
//           </div>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Programs from the past</p>
//           {archivedPrograms.length > 0 ? (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {archivedPrograms.map((program) => (
//                     <div
//                       key={program.id}
//                       className="min-w-[250px] tracking-[.1em] cursor-pointer"
//                       onClick={() => handleArchivedProgramClick(program)}
//                     >
//                       <img src={program.image} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.pubDate}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <p className="text-gray-900 dark:text-gray-100">No archived programs available.</p>
//           )}
//         </div>

//         {/* Movies Section */}
//         <div className="p-8 pt-0 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Movie <span className="text-blue-600">Reviews</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Get every friday reviews</p>
//           {isLoadingMovies ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading movies...</p>
//           ) : moviesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading movies: {moviesError}</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {movies.map((movie) => (
//                     <div key={movie.PK} className="min-w-[220px] max-w-[200px]">
//                       <img src={movie.thumbnail} alt={movie.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{movie.title}</h3>
//                         <div className="flex items-center space-x-2">
//                           <div className="flex items-center">
//                             {[...Array(5)].map((star, index) => (
//                               <FaStar
//                                 key={index}
//                                 className={index < movie.stars ? 'text-black-500' : 'text-gray-400'}
//                               />
//                             ))}
//                           </div>
//                           <button onClick={() => handleToggleLike(movie.PK)}>
//                             {movie.likedByUser ? (
//                               <FaHeart className="text-red-500" />
//                             ) : (
//                               <FaRegHeart className="text-gray-400" />
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Banner Ads Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative px-16">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-full h-60 object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Magazine Section */}
//         <div className="p-8 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-lg font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Magazines
//           </h2>
//           <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Read our latest magazine</p>
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading magazines...</p>
//           ) : (
//             <div className="relative px-16">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   <div className="min-w-[220px] max-w-[200px]">
//                     <a href={magazinePdf} target="_blank" rel="noopener noreferrer">
//                       <img src={magazineCover} alt="Surabhi Spoorthi" className="w-full h-80 object-cover rounded-lg" />
//                     </a>
//                     <div className="pt-2">
//                       <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 line-clamp-2">Surabhi Spoorthi</h3>
//                       <p className="text-gray-600 dark:text-gray-400">Latest Issue</p>
//                     </div>
//                   </div>
//                   {/* Add more magazines here as needed */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
//   <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
//     <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
//   </button>
// ));

// export default SVerse;

// // src/pages/SVerse.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchMoviesList, toggleLike } from '../redux/actions/moviesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import { fetchEventsList } from '../redux/actions/eventsActions'; // Import the fetchEventsList action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import magazineCover from '../assets/magazine1.png'; // Import the new magazine image
// import "../styles/global.css";
// import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
// import StreamingBar from '../components/StreamingBar';
// import 'react-datepicker/dist/react-datepicker.css';

// const SVerse = () => {
//   const dispatch = useDispatch();
//   const movies = useSelector((state) => state.movies.movies);
//   const isLoadingMovies = useSelector((state) => state.movies.loading);
//   const moviesError = useSelector((state) => state.movies.error);

//   const ads = useSelector((state) => state.ads.ads);
//   const isLoadingAds = useSelector((state) => state.ads.loading);
//   const adsError = useSelector((state) => state.ads.error);

//   const events = useSelector((state) => state.events.events); // Get events from the Redux state
//   const isLoadingEvents = useSelector((state) => state.events.loading);
//   const eventsError = useSelector((state) => state.events.error);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);

//   useEffect(() => {
//     dispatch(fetchMoviesList());
//     dispatch(fetchAds(true));
//     dispatch(fetchEventsList()); // Fetch events list
//   }, [dispatch]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setBannerAdIndex(prevIndex => prevIndex + 1);
//     }, 300000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleToggleLike = (movieId) => {
//     dispatch(toggleLike(movieId));
//   };

//   return (
//     <div className="relative font-sans min-h-screen pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         {/* Events Section */}
//         <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Upcoming <span className="text-blue-600">Events</span>
//           </h2>
//           {isLoadingEvents ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
//           ) : eventsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
//           ) : events.length === 0 ? (
//             <p className="text-gray-900 dark:text-gray-100">No upcoming events available.</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {events.map((event, index) => (
//                     <div key={index} className="min-w-[220px] max-w-[200px]">
//                       <img src={event.eventImage} alt={event.name} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.name}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{new Date(event.date).toLocaleDateString()}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Movies Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Movie <span className="text-blue-600">Reviews</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Get every friday reviews</p>
//           {isLoadingMovies ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading movies...</p>
//           ) : moviesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading movies: {moviesError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {movies.map((movie) => (
//                     <div key={movie.PK} className="min-w-[220px] max-w-[200px]">
//                       <img src={movie.thumbnail} alt={movie.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{movie.title}</h3>
//                         <div className="flex items-center space-x-2">
//                           <div className="flex items-center">
//                             {[...Array(5)].map((star, index) => (
//                               <FaStar
//                                 key={index}
//                                 className={index < movie.stars ? 'text-black-500' : 'text-gray-400'}
//                               />
//                             ))}
//                           </div>
//                           <button onClick={() => handleToggleLike(movie.PK)}>
//                             {movie.likedByUser ? (
//                               <FaHeart className="text-red-500" />
//                             ) : (
//                               <FaRegHeart className="text-gray-400" />
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Magazine Section */}
//         <div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-lg font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Magazines
//           </h2>
//           <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Read our latest magazine</p>
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading magazines...</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   <div className="min-w-[220px] max-w-[200px]">
//                     <a href="https://anyflip.com/raljz/naqi/" target="_blank" rel="noopener noreferrer">
//                       <img src={magazineCover} alt="Surabhi Spotlight" className="w-full h-80 object-cover rounded-lg" />
//                     </a>
//                     <div className="pt-2">
//                       <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 line-clamp-2">Surabhi Spotlight</h3>
//                       <p className="text-gray-600 dark:text-gray-400">Latest Issue</p>
//                     </div>
//                   </div>
//                   {/* Add more magazines here as needed */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
//   <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
//     <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
//   </button>
// ));

// export default SVerse;


// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchMoviesList, toggleLike } from '../redux/actions/moviesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import { fetchEventsList } from '../redux/actions/eventsActions'; // Import the fetchEventsList action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import magazineCover from '../assets/magazine1.png'; // Import the new magazine image
// import "../styles/global.css";
// import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
// import StreamingBar from '../components/StreamingBar';
// import 'react-datepicker/dist/react-datepicker.css';

// const SVerse = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const movies = useSelector((state) => state.movies.movies);
//   const isLoadingMovies = useSelector((state) => state.movies.loading);
//   const moviesError = useSelector((state) => state.movies.error);

//   const ads = useSelector((state) => state.ads.ads);
//   const isLoadingAds = useSelector((state) => state.ads.loading);
//   const adsError = useSelector((state) => state.ads.error);

//   const events = useSelector((state) => state.events.events); // Get events from the Redux state
//   const isLoadingEvents = useSelector((state) => state.events.isLoading);
//   const eventsError = useSelector((state) => state.events.errorMessage);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);

//   useEffect(() => {
//     if (userData) {
//       dispatch(fetchMoviesList());
//       dispatch(fetchAds(true));
//       dispatch(fetchEventsList(userData.id, userData.identityToken)); // Fetch events list
//     }
//   }, [dispatch, userData]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds[bannerAdIndex % bannerAds.length];


//   useEffect(() => {
//     if (bannerAds.length > 0) {
//       const showRandomBannerAd = () => {
//         const randomIndex = Math.floor(Math.random() * bannerAds.length);
//         setBannerAdIndex(randomIndex);
//       };

//       showRandomBannerAd();

//       const interval = setInterval(() => {
//         showRandomBannerAd();
//       }, 180000);

//       return () => clearInterval(interval);
//     }
//   }, [bannerAds]);

//   const handleToggleLike = (movieId) => {
//     dispatch(toggleLike(movieId));
//   };

//   return (
//     <div className="relative font-sans min-h-screen pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         {/* Events Section */}
//         <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Upcoming <span className="text-blue-600">Events</span>
//           </h2>
//           {isLoadingEvents ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
//           ) : eventsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
//           ) : events.length === 0 ? (
//             <p className="text-gray-900 dark:text-gray-100">No upcoming events available.</p>
//           ) : (
//             <div className="relative pt-4">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {events.map((event, index) => (
//                     <div key={index} className="min-w-[220px] max-w-[200px]">
//                       <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
//                         <img src={event.eventPic} alt={event.eventTitle} className="w-full h-60 object-cover rounded-[20px]" />
//                       </a>
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.eventTitle}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventTime}</p>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventLocation}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Movies Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Movie <span className="text-blue-600">Reviews</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Get every friday reviews</p>
//           {isLoadingMovies ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading movies...</p>
//           ) : moviesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading movies: {moviesError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {movies.map((movie) => (
//                     <div key={movie.PK} className="min-w-[220px] max-w-[200px]">
//                       <img src={movie.thumbnail} alt={movie.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{movie.title}</h3>
//                         <div className="flex items-center space-x-2">
//                           <div className="flex items-center">
//                             {[...Array(5)].map((star, index) => (
//                               <FaStar
//                                 key={index}
//                                 className={index < movie.stars ? 'text-black-500 dark:text-gray-200 ' : 'text-gray-400 dark:text-gray-100 '}
//                               />
//                             ))}
//                           </div>
//                           <button onClick={() => handleToggleLike(movie.PK)}>
//                             {movie.likedByUser ? (
//                               <FaHeart className="text-red-500" />
//                             ) : (
//                               <FaRegHeart className="text-gray-400" />
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Magazine Section */}
//         <div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-lg font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Magazines
//           </h2>
//           <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Read our latest magazine</p>
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading magazines...</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   <div className="min-w-[300px] max-w-[200px]">
//                     <a href="https://anyflip.com/raljz/naqi/" target="_blank" rel="noopener noreferrer">
//                       <img src={magazineCover} alt="Surabhi Spotlight" className="w-full h-96 object-cover rounded-lg" />
//                     </a>
//                     <div className="pt-2">
//                       <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 line-clamp-2">Surabhi Spotlight</h3>
//                       <p className="text-gray-600 dark:text-gray-400">Latest Issue</p>
//                     </div>
//                   </div>
//                   {/* Add more magazines here as needed */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
//   <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
//     <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
//   </button>
// ));

// export default SVerse;

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchMoviesList, toggleLike } from '../redux/actions/moviesActions';
// import { fetchAds } from '../redux/actions/adsActions';
// import { fetchEventsList } from '../redux/actions/eventsActions'; // Import the fetchEventsList action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import magazineCover from '../assets/magazine1.png'; // Import the new magazine image
// import "../styles/global.css";
// import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
// import StreamingBar from '../components/StreamingBar';
// import 'react-datepicker/dist/react-datepicker.css';

// const SVerse = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const movies = useSelector((state) => state.movies.movies);
//   const isLoadingMovies = useSelector((state) => state.movies.loading);
//   const moviesError = useSelector((state) => state.movies.error);

//   const ads = useSelector((state) => state.ads.ads) || []; // Ensure ads is an array
//   const isLoadingAds = useSelector((state) => state.ads.loading);
//   const adsError = useSelector((state) => state.ads.error);

//   const currentEvents = useSelector((state) => state.events.currentEvents) || []; // Ensure currentEvents is an array
//   const previousEvents = useSelector((state) => state.events.previousEvents) || []; // Ensure previousEvents is an array
//   const isLoadingEvents = useSelector((state) => state.events.isLoading);
//   const eventsError = useSelector((state) => state.events.errorMessage);

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [bannerAdIndex, setBannerAdIndex] = useState(0);

//   useEffect(() => {
//     if (userData) {
//       dispatch(fetchMoviesList());
//       dispatch(fetchAds(true));
//       dispatch(fetchEventsList(userData.id, userData.identityToken)); // Fetch events list
//     }
//   }, [dispatch, userData]);

//   const bannerAds = ads.filter(ad => ad.type === '2');
//   const currentBannerAd = bannerAds?.[bannerAdIndex % bannerAds.length];

//   useEffect(() => {
//     if (bannerAds.length > 0) {
//       const showRandomBannerAd = () => {
//         const randomIndex = Math.floor(Math.random() * bannerAds.length);
//         setBannerAdIndex(randomIndex);
//       };

//       showRandomBannerAd();

//       const interval = setInterval(() => {
//         showRandomBannerAd();
//       }, 180000);

//       return () => clearInterval(interval);
//     }
//   }, [bannerAds]);

//   const handleToggleLike = (movieId) => {
//     dispatch(toggleLike(movieId));
//   };

//   return (
//     <div className="relative font-sans min-h-screen pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         {/* Upcoming Events Section */}
//         <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Upcoming <span className="text-blue-600">Events</span>
//           </h2>
//           {isLoadingEvents ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
//           ) : eventsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
//           ) : currentEvents.length === 0 ? (
//             <p className="text-gray-900 dark:text-gray-100">No upcoming events available.</p>
//           ) : (
//             <div className="relative pt-4">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {currentEvents.map((event, index) => (
//                     <div key={index} className="min-w-[220px] max-w-[200px]">
//                       <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
//                         <img src={event.eventPic} alt={event.eventTitle} className="w-full h-60 object-cover rounded-[20px]" />
//                       </a>
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.eventTitle}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventTime}</p>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventLocation}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Previous Events Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Previous <span className="text-blue-600">Events</span>
//           </h2>
//           {isLoadingEvents ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
//           ) : eventsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
//           ) : previousEvents.length === 0 ? (
//             <p className="text-gray-900 dark:text-gray-100">No previous events available.</p>
//           ) : (
//             <div className="relative pt-4">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {previousEvents.map((event, index) => (
//                     <div key={index} className="min-w-[220px] max-w-[200px]">
//                       <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
//                         <img src={event.eventPic} alt={event.eventTitle} className="w-full h-60 object-cover rounded-[20px]" />
//                       </a>
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.eventTitle}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventTime}</p>
//                         <p className="text-gray-600 dark:text-gray-400">{event.eventLocation}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Movies Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Movie <span className="text-blue-600">Reviews</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Get every friday reviews</p>
//           {isLoadingMovies ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading movies...</p>
//           ) : moviesError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading movies: {moviesError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {movies.map((movie) => (
//                     <div key={movie.PK} className="min-w-[220px] max-w-[200px]">
//                       <img src={movie.thumbnail} alt={movie.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="pt-2">
//                         <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{movie.title}</h3>
//                         <div className="flex items-center space-x-2">
//                           <div className="flex items-center">
//                             {[...Array(5)].map((star, index) => (
//                               <FaStar
//                                 key={index}
//                                 className={index < movie.stars ? 'text-black-500 dark:text-gray-200 ' : 'text-gray-400 dark:text-gray-100 '}
//                               />
//                             ))}
//                           </div>
//                           <button onClick={() => handleToggleLike(movie.PK)}>
//                             {movie.likedByUser ? (
//                               <FaHeart className="text-red-500" />
//                             ) : (
//                               <FaRegHeart className="text-gray-400" />
//                             )}
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Banner Ads Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading ads...</p>
//           ) : adsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading ads: {adsError}</p>
//           ) : (
//             <div className="relative">
//               {currentBannerAd && (
//                 <div className="flex justify-center items-center">
//                   <a href={currentBannerAd.redirectionLink} target="_blank" rel="noopener noreferrer">
//                     <img src={currentBannerAd.thumbnail} alt={currentBannerAd.title} className="w-auto h-auto object-cover rounded-lg" />
//                   </a>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>

//         {/* Magazine Section */}
//         <div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-lg font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Magazines
//           </h2>
//           <p className="text-sm text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Read our latest magazine</p>
//           {isLoadingAds ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading magazines...</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   <div className="min-w-[300px] max-w-[200px]">
//                     <a href="https://anyflip.com/raljz/naqi/" target="_blank" rel="noopener noreferrer">
//                       <img src={magazineCover} alt="Surabhi Spotlight" className="w-full h-96 object-cover rounded-lg" />
//                     </a>
//                     <div className="pt-2">
//                       <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100 line-clamp-2">Surabhi Spotlight</h3>
//                       <p className="text-gray-600 dark:text-gray-400">Latest Issue</p>
//                     </div>
//                   </div>
//                   {/* Add more magazines here as needed */}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
//   <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
//     <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
//   </button>
// ));

// export default SVerse;


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMoviesList, toggleLike } from '../redux/actions/moviesActions';
import { fetchAds } from '../redux/actions/adsActions';
import { fetchEventsList } from '../redux/actions/eventsActions'; // Import the fetchEventsList action
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import panoramaImage from '../assets/banner1.jpg'; // Replace with actual image path
import podcastImage from '../assets/banner1.jpg'; // Replace with actual image path
import spotlightImage from '../assets/banner1.jpg'; // Replace with actual image path
import followUsImage from '../assets/banner1.jpg'; // Replace with actual image path
import "../styles/global.css";
import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
import StreamingBar from '../components/StreamingBar';
import 'react-datepicker/dist/react-datepicker.css';
import { FaFacebookSquare, FaInstagram, FaYoutube } from 'react-icons/fa';
import { fetchPanoramaList } from '../redux/actions/panoramaActions';
import { fetchPodcastList } from '../redux/actions/podcastActions';
import { fetchMagazineList } from '../redux/actions/magazineActions';

const SVerse = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const movies = useSelector((state) => state.movies.movies);
  const isLoadingMovies = useSelector((state) => state.movies.loading);
  const moviesError = useSelector((state) => state.movies.error);

  const ads = useSelector((state) => state.ads.ads) || []; // Ensure ads is an array
  const isLoadingAds = useSelector((state) => state.ads.loading);
  const adsError = useSelector((state) => state.ads.error);

  const currentEvents = useSelector((state) => state.events.currentEvents) || []; // Ensure currentEvents is an array
  const previousEvents = useSelector((state) => state.events.previousEvents) || []; // Ensure previousEvents is an array
  const isLoadingEvents = useSelector((state) => state.events.isLoading);
  const eventsError = useSelector((state) => state.events.errorMessage);

  const panoramas = useSelector((state) => state.panorama.panoramas);
  const podcasts = useSelector((state) => state.podcast.podcasts);
  const magazines = useSelector((state) => state.magazine.magazines);
  const isLoadingPodcasts = useSelector((state) => state.podcast.isLoading);
  const isLoadingMagazines = useSelector((state) => state.magazine.isLoading);

  const isLoadingPanoramas = useSelector((state) => state.panorama.isLoading);
  const panoramaError = useSelector((state) => state.panorama.errorMessage);
  const podcastError = useSelector((state) => state.podcast.errorMessage);
  const magazineError = useSelector((state) => state.magazine.errorMessage);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [bannerAdIndex, setBannerAdIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showPodcastModal, setShowPodcastModal] = useState(false);
  const [showMagazineModal, setShowMagazineModal] = useState(false);

  useEffect(() => {
    if (userData) {
      dispatch(fetchMoviesList());
      dispatch(fetchAds(true));
      dispatch(fetchEventsList(userData.id, userData.identityToken)); // Fetch events list
      dispatch(fetchPanoramaList());
      dispatch(fetchPodcastList());
      dispatch(fetchMagazineList());
    }
  }, [dispatch, userData]);

  const bannerAds = ads.filter((ad) => ad.type === '2');
  const currentBannerAd = bannerAds?.[bannerAdIndex % bannerAds.length];

  useEffect(() => {
    if (bannerAds.length > 0) {
      const showRandomBannerAd = () => {
        const randomIndex = Math.floor(Math.random() * bannerAds.length);
        setBannerAdIndex(randomIndex);
      };

      showRandomBannerAd();

      const interval = setInterval(() => {
        showRandomBannerAd();
      }, 180000);

      return () => clearInterval(interval);
    }
  }, [bannerAds]);

  const handleToggleLike = (movieId) => {
    dispatch(toggleLike(movieId));
  };

  const latestPanorama = panoramas.length > 0 ? panoramas[0] : null;
  const latestPodcast = podcasts.length > 0 ? podcasts[0] : null;
  const latestMagazine = magazines.length > 0 ? magazines[0] : null;

  return (
    <div className="relative font-sans min-h-screen pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        {/* Upcoming Events Section */}
        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Upcoming <span className="text-blue-600">Events</span>
          </h2>
          {isLoadingEvents ? (
            <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
          ) : eventsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
          ) : currentEvents.length === 0 ? (
            <p className="text-gray-900 dark:text-gray-100">No upcoming events available.</p>
          ) : (
            <div className="relative pt-4">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {currentEvents.map((event, index) => (
                    <div key={index} className="min-w-[220px] max-w-[200px]">
                      <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
                        <img src={event.eventPic} alt={event.eventTitle} className="w-full h-60 object-cover rounded-[20px]" />
                      </a>
                      <div className="pt-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.eventTitle}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{event.eventTime}</p>
                        <p className="text-gray-600 dark:text-gray-400">{event.eventLocation}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Previous Events Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Previous <span className="text-blue-600">Events</span>
          </h2>
          {isLoadingEvents ? (
            <p className="text-gray-900 dark:text-gray-100">Loading events...</p>
          ) : eventsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading events: {eventsError}</p>
          ) : previousEvents.length === 0 ? (
            <p className="text-gray-900 dark:text-gray-100">No previous events available.</p>
          ) : (
            <div className="relative pt-4">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {previousEvents.map((event, index) => (
                    <div key={index} className="min-w-[220px] max-w-[200px]">
                      <a href={event.eventLink} target="_blank" rel="noopener noreferrer">
                        <img src={event.eventPic} alt={event.eventTitle} className="w-full h-60 object-cover rounded-[20px]" />
                      </a>
                      <div className="pt-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{event.eventTitle}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{event.eventTime}</p>
                        <p className="text-gray-600 dark:text-gray-400">{event.eventLocation}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Movies Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Movie <span className="text-blue-600">Reviews</span>
          </h2>
          <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Get every friday reviews</p>
          {isLoadingMovies ? (
            <p className="text-gray-900 dark:text-gray-100">Loading movies...</p>
          ) : moviesError ? (
            <p className="text-red-600 dark:text-red-400">Error loading movies: {moviesError}</p>
          ) : (
            <div className="relative">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {movies.map((movie) => (
                    <div key={movie.PK} className="min-w-[220px] max-w-[200px]">
                      <img src={movie.thumbnail} alt={movie.title} className="w-full h-60 object-cover rounded-[20px]" />
                      <div className="pt-2">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 line-clamp-2">{movie.title}</h3>
                        <div className="flex items-center space-x-2">
                          <div className="flex items-center">
                            {[...Array(5)].map((star, index) => (
                              <FaStar
                                key={index}
                                className={index < movie.stars ? 'text-black-500 dark:text-gray-200 ' : 'text-gray-400 dark:text-gray-100 '}
                              />
                            ))}
                          </div>
                          <button onClick={() => handleToggleLike(movie.PK)}>
                            {movie.likedByUser ? (
                              <FaHeart className="text-red-500" />
                            ) : (
                              <FaRegHeart className="text-gray-400" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Surabhi Verse Section (Updated Magazine Section) */}
        <div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Surabhi <span className="text-blue-600">Verse</span>
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-8">
            The exclusive content of Radio Surabhi
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            {isLoadingPanoramas ? (
                <p className="text-gray-900 dark:text-gray-100">Loading panoramas...</p>
              ) : panoramaError ? (
                <p className="text-red-600 dark:text-red-400">Error loading panoramas: {panoramaError}</p>
              ) : panoramas.length === 0 ? (
                <p className="text-gray-900 dark:text-gray-100">No panoramas available.</p>
              ) : (
                <>
                  {/* Latest Panorama Card */}
                  {latestPanorama && (
                    <div
                      key={latestPanorama.PK}
                      className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 text-center"
                    >
                      {/* Title */}
                      <h2 className="text-xl font-bold dark:text-gray-100 mb-4">
                        <span className="text-black dark:text-gray-100">Surabhi</span>{' '}
                        <span className="text-blue-600">Panorama</span>
                      </h2>
                      <a
                        href={latestPanorama.panoramaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={latestPanorama.panoramaCover}
                          alt={latestPanorama.panoramaTitle}
                          className="w-full h-1/2 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-lg font-bold text-black dark:text-gray-100 mb-2 line-clamp-2">
                          {latestPanorama.panoramaTitle}
                        </h3>
                      </a>
                      {/* Show More Button */}
                      {/* Show More Button (Only if more than 1 item) */}
                      {panoramas.length > 1 && (
                        <button
                          onClick={() => setShowModal(true)}
                          className="mt-4 mb-0 px-2 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                          Show More
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Latest Podcast Card */}
              {isLoadingPodcasts ? (
                <p className="text-gray-900 dark:text-gray-100">Loading Podcast...</p>
              ) : panoramaError ? (
                <p className="text-red-600 dark:text-red-400">Error loading Podcast: {podcastError}</p>
              ) : podcasts.length === 0 ? (
                <p className="text-gray-900 dark:text-gray-100">No Podcast available.</p>
              ) : (
                <>
                  {/* Latest Panorama Card */}
                  {latestPodcast && (
                    <div
                      key={latestPodcast.PK}
                      className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 text-center"
                    >
                      {/* Title */}
                      <h2 className="text-xl font-bold dark:text-gray-100 mb-4">
                        <span className="text-black dark:text-gray-100">Surabhi</span>{' '}
                        <span className="text-blue-600">Podcast</span>
                      </h2>
                      <a
                        href={latestPodcast.podcastLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={latestPodcast.podcastCover}
                          alt={latestPodcast.podcastTitle}
                          className="w-full h-1/2 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-lg font-bold text-black dark:text-gray-100 mb-2 line-clamp-2">
                          {latestPodcast.podcastTitle}
                        </h3>
                      </a>
                      {/* Show More Button (Only if more than 1 item) */}
                      {podcasts.length > 1 && (
                        <button
                          onClick={() => setShowPodcastModal(true)}
                          className="mt-4 mb-0 px-2 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                        >
                          Show More
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Latest Magazine Card */} 
            {isLoadingMagazines ? (
                <p className="text-gray-900 dark:text-gray-100">Loading Magazine...</p>
              ) : panoramaError ? (
                <p className="text-red-600 dark:text-red-400">Error loading Magazine: {podcastError}</p>
              ) : podcasts.length === 0 ? (
                <p className="text-gray-900 dark:text-gray-100">No Magazine available.</p>
              ) : (
                <>
                  {/* Latest Magazine Card */}
                  {latestMagazine && (
                    <div
                      key={latestMagazine.PK}
                      className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6 text-center"
                    >
                      {/* Title */}
                      <h2 className="text-xl font-bold dark:text-gray-100 mb-4">
                        <span className="text-black dark:text-gray-100">Surabhi</span>{' '}
                        <span className="text-blue-600">Spotlight</span>
                      </h2>
                      <a
                        href={latestMagazine.magazineUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={latestMagazine.magazineCover}
                          alt={latestMagazine.magazineTitle}
                          className="w-full h-1/2 object-cover rounded-md mb-4"
                        />
                        <h3 className="text-lg font-bold text-black dark:text-gray-100 mb-2 line-clamp-2">
                          {latestMagazine.magazineTitle}
                        </h3>
                      </a>
                      {/* Show More Button */}
                     {/* Show More Button (Only if more than 1 item) */}
                    {magazines.length > 1 && (
                      <button
                        onClick={() => setShowMagazineModal(true)}
                        className="mt-4 mb-0 px-2 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                      >
                        Show More
                      </button>
                    )}
                    </div>
                  )}
                </>
              )}
            {/* Follow Us On Card */}
            <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6  text-center">
              <h3 className="text-lg font-bold text-black dark:text-gray-100 mb-4">Follow Us On</h3>
              <p className="text-sm text-gray-600 dark:text-gray-400 mb-10">Follow Radio Surabhi at</p>

              {/* Facebook */}
              <div className="mb-24">
                <a
                  href="https://www.facebook.com/RadioSurabhi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <FaFacebookSquare className="text-blue-600 text-5xl mb-2" />
                  <span className="text-sm font-bold text-gray-800 dark:text-gray-200">@radiosurabhi</span>
                </a>
              </div>

              {/* Instagram */}
              <div className="mb-24">
                <a
                  href="https://www.instagram.com/radiosurabhi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <FaInstagram className="text-pink-500 text-5xl mb-2" />
                  <span className="text-sm font-bold text-gray-800 dark:text-gray-200">@radiosurabhi</span>
                </a>
              </div>

              {/* YouTube */}
              <div>
                <a
                  href="https://www.youtube.com/RadioSurabhi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center"
                >
                  <FaYoutube className="text-red-600 text-5xl mb-2" />
                  <span className="text-sm font-bold text-gray-800 dark:text-gray-200">@radiosurabhi</span>
                </a>
              </div>
            </div>

          </div>
        </div>

        {/* Modal for Show More */}
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-md w-full relative">
              {/* Title */}
              <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-6">
                All Panoramas
              </h3>

              {/* Panorama List */}
              <ul className="space-y-6">
                {panoramas.map((panorama) => (
                  <li key={panorama.PK} className="flex items-center justify-between">
                    {/* Panorama Image and Title */}
                    <a
                      href={panorama.panoramaLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center space-x-4"
                    >
                      <img
                        src={panorama.panoramaCover}
                        alt={panorama.panoramaTitle}
                        className="w-12 h-12 rounded-lg object-cover"
                      />
                      <span className="text-blue-600 hover:underline">
                        {panorama.panoramaTitle}
                      </span>
                    </a>
                    {/* Created At Date */}
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {new Date(panorama.createdAt).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>

              {/* Close Button */}
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal for ShowPodcast More */}
        {showPodcastModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-md w-full relative">
              {/* Title */}
              <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-6">
                All Podcasts
              </h3>

              {/* Panorama List */}
              <ul className="space-y-6">
                {podcasts.map((podcast) => (
                  <li key={podcast.PK} className="flex items-center justify-between">
                    {/* Panorama Image and Title */}
                    <a
                      href={podcast.podcastLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center space-x-4"
                    >
                      <img
                        src={podcast.podcastCover}
                        alt={podcast.podcastaTitle}
                        className="w-12 h-12 rounded-lg object-cover"
                      />
                      <span className="text-blue-600 hover:underline">
                        {podcast.podcastTitle}
                      </span>
                    </a>
                    {/* Created At Date */}
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {new Date(podcast.createdAt).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>

              {/* Close Button */}
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => setShowPodcastModal(false)}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal for ShowPodcast More */}
        {showMagazineModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-8 max-w-md w-full relative">
              {/* Title */}
              <h3 className="text-2xl font-bold text-gray-900 dark:text-gray-100 text-center mb-6">
                All Spotlights
              </h3>

              {/* Panorama List */}
              <ul className="space-y-6">
                {magazines.map((magazine) => (
                  <li key={magazine.PK} className="flex items-center justify-between">
                    {/* Panorama Image and Title */}
                    <a
                      href={magazine.magazineUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center space-x-4"
                    >
                      <img
                        src={magazine.magazineCover}
                        alt={magazine.magazineTitle}
                        className="w-12 h-12 rounded-lg object-cover"
                      />
                      <span className="text-blue-600 hover:underline">
                        {magazine.magazineTitle}
                      </span>
                    </a>
                    {/* Created At Date */}
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {new Date(magazine.magazinePubDate).toLocaleDateString()}
                    </span>
                  </li>
                ))}
              </ul>

              {/* Close Button */}
              <div className="flex justify-center mt-8">
                <button
                  onClick={() => setShowMagazineModal(false)}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}


        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

export default SVerse;
