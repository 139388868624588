import { fetchPodcastStart, fetchPodcastSuccess, fetchPodcastFailure } from '../reducers/podcastSlice';
import apiService from '../api-service/apiService';

export const fetchPodcastList = () => async (dispatch, getState) => {
  dispatch(fetchPodcastStart());

  try {
    const { userData } = getState().user;
    const podcasts = await apiService.fetchPodcastList(userData);
    dispatch(fetchPodcastSuccess(podcasts));
  } catch (error) {
    dispatch(fetchPodcastFailure(error.message));
  }
};
