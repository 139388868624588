// // src/pages/Schedule.js
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchProgramsList } from '../redux/actions/programsActions';
// import { fetchSponsorsList } from '../redux/actions/sponsorsActions';
// import { fetchArchivedPrograms } from '../redux/actions/archiveActions'; // Import the fetchArchivedPrograms action
// import NavigationBar from '../components/NavigationBar';
// import banner from '../assets/cover 007.png';
// import "../styles/global.css";
// import "../styles/carousel.css"; // Add a new CSS file for carousel styles
// import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt } from 'react-icons/fa';
// import Slider from "react-slick";
// import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { setArchiveUrl, setProgram } from '../redux/reducers/streamingBarSlice';

// const Schedule = () => {
//   const dispatch = useDispatch();
//   const userData = useSelector((state) => state.user.userData);
//   const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
//   const { sponsors, isLoading: isSponsorsLoading, errorMessage: sponsorsError } = useSelector((state) => state.sponsors);
//   const { archivedPrograms, isLoading: isArchivedLoading, errorMessage: archivedError } = useSelector((state) => state.archive); // Get archived programs from the Redux state

//   const [isCollapsed, setIsCollapsed] = useState(true);
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   useEffect(() => {
//     dispatch(fetchProgramsList(true)); // Fetch programs for guest
//     dispatch(fetchSponsorsList()); // Fetch sponsors list
//     dispatch(fetchArchivedPrograms(selectedDate)); // Fetch archived programs for the selected date
//   }, [dispatch, selectedDate]);

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   const handleArchivedProgramClick = (program) => {
//     dispatch(setArchiveUrl(program.playLink));
//     dispatch(setProgram({
//       showPic: program.image,
//       title: program.title,
//       rjName: program.rjName || ''
//     }));
//   };

//   if (!userData) {
//     return <p>Loading...</p>;
//   }

//   const carouselSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//     arrows: true,
//     centerMode: true,
//     centerPadding: "0",
//   };

//   return (
//     <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
//       <div className="fixed h-screen z-50">
//         <NavigationBar setIsCollapsed={setIsCollapsed} />
//       </div>
//       <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
//         <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Today's <span className="text-blue-600">Schedule</span>
//           </h2>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]"> </p>
//           {isProgramsLoading ? (
//             <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
//           ) : programsError ? (
//             <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
//           ) : (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {programs.map((program) => (
//                     <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
//                       <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Sponsors Section */}
//         <div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
//           <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
//             Our <span className="text-blue-600">Sponsors</span>
//           </h2>
//           <div className="relative carousel-wrapper">
//             <Slider {...carouselSettings}>
//               {sponsors.map((sponsor, index) => (
//                 <div key={index} className="p-4 md:p-14">
//                   <div className="carousel-item">
//                     <img src={sponsor.sponsorLogo} alt={sponsor.name} className="w-full h-full object-cover rounded-lg" />
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>

//         {/* Archived Programs Section */}
//         <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
//           <div className="flex justify-between items-center mb-4">
//             <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-[.10em]">
//               Archived <span className="text-blue-600">Programs</span>
//             </h2>
//             <div className="flex items-center space-x-2 pr-36">
//               <DatePicker
//                 selected={selectedDate}
//                 onChange={handleDateChange}
//                 maxDate={new Date()}
//                 minDate={new Date().setDate(new Date().getDate() - 25)}
//                 customInput={<CustomDateInput />}
//               />
//             </div>
//           </div>
//           <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Programs from the past</p>
//           {archivedPrograms.length > 0 ? (
//             <div className="relative">
//               <div className="no-scrollbar overflow-x-scroll">
//                 <div className="flex space-x-6">
//                   {archivedPrograms.map((program) => (
//                     <div
//                       key={program.id}
//                       className="min-w-[250px] tracking-[.1em] cursor-pointer"
//                       onClick={() => handleArchivedProgramClick(program)}
//                     >
//                       <img src={program.image} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
//                       <div className="p-2">
//                         <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
//                         <p className="text-gray-600 dark:text-gray-400">{program.pubDate}</p>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           ) : (
//             <p className="text-gray-900 dark:text-gray-100">No archived programs available.</p>
//           )}
//         </div>
        
//         {/* Add the StreamingBar component here */}
//         <StreamingBar isCollapsed={isCollapsed} />
//       </div>
//     </div>
//   );
// };

// const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
//   <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
//     <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
//   </button>
// ));

// export default Schedule;

// src/pages/Schedule.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProgramsList } from '../redux/actions/programsActions';
import { fetchSponsorsList } from '../redux/actions/sponsorsActions';
import { fetchArchivedPrograms } from '../redux/actions/archiveActions'; // Import the fetchArchivedPrograms action
import NavigationBar from '../components/NavigationBar';
import banner from '../assets/cover 007.png';
import "../styles/global.css";
import "../styles/carousel.css"; // Add a new CSS file for carousel styles
import { FaHeart, FaRegHeart, FaStar, FaCalendarAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import Slider from "react-slick";
import StreamingBar from '../components/StreamingBar'; // Import the StreamingBar component
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { setArchiveUrl, setProgram } from '../redux/reducers/streamingBarSlice';

// Utility function to get yesterday's date
const getYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  return yesterday;
};

const Schedule = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const { programs, isLoading: isProgramsLoading, errorMessage: programsError } = useSelector((state) => state.programs);
  const { sponsors, isLoading: isSponsorsLoading, errorMessage: sponsorsError } = useSelector((state) => state.sponsors);
  const { archivedPrograms, isLoading: isArchivedLoading, errorMessage: archivedError } = useSelector((state) => state.archive); // Get archived programs from the Redux state

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selectedDate, setSelectedDate] = useState(getYesterday()); // Initialize with yesterday's date

  useEffect(() => {
    dispatch(fetchProgramsList(true)); // Fetch programs for guest
    dispatch(fetchSponsorsList()); // Fetch sponsors list
    dispatch(fetchArchivedPrograms(selectedDate)); // Fetch archived programs for the selected date
  }, [dispatch, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleArchivedProgramClick = (program) => {
    dispatch(setArchiveUrl(program.playLink));
    dispatch(setProgram({
      showPic: program.image,
      title: program.title,
      rjName: program.rjName || ''
    }));
  };

  if (!userData) {
    return <p>Loading...</p>;
  }

  const carouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "0",
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  function CustomPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaArrowLeft
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", position: "absolute", left: "10px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  }

  function CustomNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <FaArrowRight
        className={`${className} custom-arrow`}
        style={{ ...style, display: "block", position: "absolute", right: "10px", zIndex: 1 }}
        onClick={onClick}
      />
    );
  }

  return (
    <div className="relative font-sans pb-20 bg-gray-50 dark:bg-gray-900">
      <div className="fixed h-screen z-50">
        <NavigationBar setIsCollapsed={setIsCollapsed} />
      </div>
      <div className={`transition-all duration-300 ease-in-out ${isCollapsed ? 'ml-0 md:ml-20' : 'ml-0 md:ml-64'}`}>
        <div className="px-4 md:px-16 pt-20 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-2 tracking-[.10em]">
            Today's <span className="text-blue-600">Schedule</span>
          </h2>
          <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]"> </p>
          {isProgramsLoading ? (
            <p className="text-gray-900 dark:text-gray-100">Loading programs...</p>
          ) : programsError ? (
            <p className="text-red-600 dark:text-red-400">Error loading programs: {programsError}</p>
          ) : (
            <div className="relative">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {programs.map((program) => (
                    <div key={program.PK} className="min-w-[250px] tracking-[.1em]">
                      <img src={program.showPic} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
                      <div className="p-2">
                        <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{program.fromTime} - {program.toTime}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Sponsors Section */}
<div className="px-4 md:px-16 pt-4 bg-gray-50 dark:bg-gray-900">
  <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 mb-6 tracking-[.10em]">
    Our <span className="text-blue-600">Sponsors</span>
  </h2>
  {/* Container for Sponsors */}
  <div className="relative max-w-7xl mx-auto carousel-wrapper bg-gray-50 dark:bg-gray-900 p-6 rounded-lg">
    <Slider {...carouselSettings}>
      {sponsors.map((sponsor, index) => (
        <div key={index} className="p-4">
          <div className="carousel-item flex items-center justify-center">
            <img
              src={sponsor.sponsorLogo}
              alt={sponsor.name}
              className="w-40 h-40 object-contain rounded-md"
            />
          </div>
        </div>
      ))}
    </Slider>
  </div>
</div>


        {/* Archived Programs Section */}
        <div className="px-4 md:px-16 pt-4 md:pt-8 bg-gray-50 dark:bg-gray-900">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-[.10em]">
              Archived <span className="text-blue-600">Programs</span>
            </h2>
            <div className="flex items-center space-x-2 pr-36">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                maxDate={new Date()}
                minDate={new Date().setDate(new Date().getDate() - 25)}
                customInput={<CustomDateInput />}
              />
            </div>
          </div>
          <p className="text-l text-gray-600 dark:text-gray-400 mb-4 tracking-[.10em]">Programs from the past</p>
          {archivedPrograms.length > 0 ? (
            <div className="relative">
              <div className="no-scrollbar overflow-x-scroll">
                <div className="flex space-x-6">
                  {archivedPrograms.map((program) => (
                    <div
                      key={program.id}
                      className="min-w-[250px] tracking-[.1em] cursor-pointer"
                      onClick={() => handleArchivedProgramClick(program)}
                    >
                      <img src={program.image} alt={program.title} className="w-full h-60 object-cover rounded-[20px]" />
                      <div className="p-2">
                        <h3 className="text-sm font-semibold text-gray-900 dark:text-gray-100 truncate overflow-hidden whitespace-nowrap">{program.title}</h3>
                        <p className="text-gray-600 dark:text-gray-400">{program.pubDate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <p className="text-gray-900 dark:text-gray-100">No archived programs available.</p>
          )}
        </div>
        
        {/* Add the StreamingBar component here */}
        <StreamingBar isCollapsed={isCollapsed} />
      </div>
    </div>
  );
};

const CustomDateInput = React.forwardRef(({ value, onClick }, ref) => (
  <button className="bg-transparent border-none p-0" onClick={onClick} ref={ref}>
    <FaCalendarAlt className="text-gray-600 dark:text-gray-400 cursor-pointer" />
  </button>
));

export default Schedule;
