import { fetchMagazineStart, fetchMagazineSuccess, fetchMagazineFailure } from '../reducers/magazineSlice';
import apiService from '../api-service/apiService';

export const fetchMagazineList = () => async (dispatch, getState) => {
  dispatch(fetchMagazineStart());

  try {
    const { userData } = getState().user;
    const magazines = await apiService.fetchMagazineList(userData);
    dispatch(fetchMagazineSuccess(magazines));
  } catch (error) {
    dispatch(fetchMagazineFailure(error.message));
  }
};
