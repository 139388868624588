// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import userReducer from './userSlice';
import programsReducer from './programsSlice';
import newsReducer from './newsSlice';
import categoriesReducer from './categoriesSlice';
import archiveReducer from './archiveSlice';
import streamingBarReducer from './streamingBarSlice';
import moviesReducer from './moviesSlice';
import sponsorsReducer from './sponsorsSlice';
import eventsReducer from './eventsSlice';
import adsReducer from './adsSlice';
import rjPostersReducer from './rjPostersSlice';
import panoramaReducer from './panormaSlice'
import podcastReducer from './podcastSlice'
import magazineReducer from './magazineSlice'

const rootReducer = combineReducers({
  user: userReducer,
  programs: programsReducer,
  news: newsReducer,
  categories: categoriesReducer,
  archive: archiveReducer,
  streamingBar: streamingBarReducer,
  movies: moviesReducer,
  sponsors: sponsorsReducer,
  events: eventsReducer,
  ads: adsReducer,
  rjPosters: rjPostersReducer, // Add the rjPosters reducer to the stores
  panorama: panoramaReducer,
  podcast: podcastReducer,
  magazine: magazineReducer
});

export default rootReducer;
