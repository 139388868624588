import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  podcasts: [],
  isLoading: false,
  errorMessage: '',
};

const podcastSlice = createSlice({
  name: 'podcast',
  initialState,
  reducers: {
    fetchPodcastStart(state) {
      state.isLoading = true;
    },
    fetchPodcastSuccess(state, action) {
      state.isLoading = false;
      state.podcasts = action.payload;
    },
    fetchPodcastFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { fetchPodcastStart, fetchPodcastSuccess, fetchPodcastFailure } = podcastSlice.actions;
export default podcastSlice.reducer;
