import { fetchPanoramaStart, fetchPanoramaSuccess, fetchPanoramaFailure } from '../reducers/panormaSlice';
import apiService from '../api-service/apiService';

export const fetchPanoramaList = () => async (dispatch, getState) => {
  dispatch(fetchPanoramaStart());

  try {
    const { userData } = getState().user;
    const panoramas = await apiService.fetchPanoramaList(userData);
    dispatch(fetchPanoramaSuccess(panoramas));
  } catch (error) {
    dispatch(fetchPanoramaFailure(error.message));
  }
};
