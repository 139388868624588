import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  magazines: [],
  isLoading: false,
  errorMessage: '',
};

const magazineSlice = createSlice({
  name: 'magazine',
  initialState,
  reducers: {
    fetchMagazineStart(state) {
      state.isLoading = true;
    },
    fetchMagazineSuccess(state, action) {
      state.isLoading = false;
      state.magazines = action.payload;
    },
    fetchMagazineFailure(state, action) {
      state.isLoading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const { fetchMagazineStart, fetchMagazineSuccess, fetchMagazineFailure } = magazineSlice.actions;
export default magazineSlice.reducer;
